import { getApps, initializeApp } from '@firebase/app'
import { activate, ensureInitialized, getRemoteConfig, isSupported, RemoteConfig } from '@firebase/remote-config'
import { Analytics, getAnalytics } from 'firebase/analytics'
import rcDefaults from './remote_config_defaults.json'

export const init = (): RemoteConfig => {
  let remoteConfig: RemoteConfig
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
  }
  
  if (isSupported()) {
    if (typeof window !== 'undefined' && !getApps().length) {
      const app = initializeApp(firebaseConfig)
      remoteConfig = getRemoteConfig(app)
      remoteConfig.defaultConfig = rcDefaults
      remoteConfig.settings.minimumFetchIntervalMillis = Number(process.env.FIREBASE_RC_FETCH_INTERVAL)
      activate(remoteConfig)
      ensureInitialized(remoteConfig)
    } else {
      const app = getApps()[0]
      initializeApp(firebaseConfig)
      remoteConfig = getRemoteConfig(app)
      remoteConfig.defaultConfig = rcDefaults
      remoteConfig.settings.minimumFetchIntervalMillis = Number(process.env.FIREBASE_RC_FETCH_INTERVAL)
      activate(remoteConfig)
      ensureInitialized(remoteConfig)
    }
    return remoteConfig
  }
}

export const initGoogleAnalytics = (): Analytics => {
  let googleAnalytics: Analytics
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
  }
  
  if (isSupported()) {
    if (typeof window !== 'undefined' && !getApps().length) {
      const app = initializeApp(firebaseConfig)
      const analytics = getAnalytics(app)
      googleAnalytics = analytics
    } else {
      const app = getApps()[0]
      initializeApp(firebaseConfig)
      const analytics = getAnalytics(app)
      googleAnalytics = analytics
    }
    return googleAnalytics
  }
}