import { fetchAndActivate, getValue, RemoteConfig } from '@firebase/remote-config'
import { captureException } from '@sentry/nextjs'
import { isJson } from '../../utils/checkIsJson'
import { init } from './FirebaseService'

export enum RemoteConfigKeys {
  TESTE = 'Teste',
  POSTS = 'PopularPosts',
  BANNER = 'Banner',
  INTERNATIONAL_BANNER = 'IternationalBanner',
  FAQ = 'FAQ',
  LPI_KICKSTARTER = 'LPIKickstarter',
  LPI_START_CAMPAIGN_DATE = 'LPIStartCampaignDate',
  LPI_START_CAMPAIGN_DATE_BANNER = 'LPIStartCampaignDateBanner',
  LPI_START_CAMPAIGN_DATE_POP_UP = 'LPIStartCampaignDatePopUp',
  LPI_PILLOW_PRICE_PROMO = 'LPIPillowPricePromo',
  LPI_PILLOW_PRICE_RETAIL = 'LPIPillowPriceRetail',
  PILLOW_QUIZ_LIST_ITEMS = 'PillowQuizListItems'
}

export const getRemoteConfigValue = async <T>(key: string): Promise<T | null> => {
  let values: T

  try {
    const remoteConfig: RemoteConfig = init()
    if (remoteConfig.defaultConfig[key] !== undefined) {
      const defaultValue = remoteConfig.defaultConfig[key] as unknown
      if (isJson(defaultValue)) {
        values = JSON.parse(defaultValue as string) as T
      } else {
        values = defaultValue as T
      }
    }

    await fetchAndActivate(remoteConfig)
    // tslint:disable-next-line
    const response = getValue(remoteConfig, key)['_value']

    if (isJson(response)) {
      values = JSON.parse(response) as T
    } else {
      values = response as T
    }
  } catch (e) {
    captureException(e)
  }

  return values
}